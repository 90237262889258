import { FirebaseError, initializeApp } from 'firebase/app';
import {
  ActionCodeSettings,
  AuthErrorCodes,
  User,
  connectAuthEmulator,
  getAuth,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
} from 'firebase/auth';
import { connectFirestoreEmulator, doc, getDoc, getFirestore } from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from 'firebase/functions';
import { createContext, useCallback, useEffect, useMemo, useReducer, useState } from 'react';

// eslint-disable-next-line import/no-cycle
import { apiSlice } from 'src/redux/rtkQuery/apiSlice';
// eslint-disable-next-line import/no-cycle
import { dispatch as StoreDispatch } from 'src/redux/store';

import { CheckLocalDev, FIREBASE_API } from '../config-global';
import { UserAccountGeneral } from '../models/mrr/userAccountGeneral';
import { PATH_HOME } from '../routes/paths';
import { cloud_createUserAsAdmin, cloud_updateUserBrand, cloud_userSignUp } from '../utils/mrr/cloudFunctions';
import { ActionMapType, AuthStateType, AuthUserType, MRRFirebaseContextType } from './types';
import { sendToSentry, sentryAdminActionsFingerprint } from '../utils/mrr/sentryReporter';
import countryConfig, { CountryConfig } from '../utils/mrr/phone/countryConfig';
import { writePhoneForSFAccount } from '../utils/mrr/phone/phone';
import { brandConfig } from '../config';


// ----------------------------------------------------------------------

export const RESTORE_GUID_STORED_IN_SESSION_KEY = 'firebase:restoreGUID';
export const ADMIN_STORED_IN_SESSION_KEY = 'firebase:UID';
export const ADMIN_STORED_IN_SESSION_ROLE = 'Firebase:Origin-role'

// ----------------------------------------------------------------------
const verboseLogging = false && CheckLocalDev()

enum Types {
  INITIAL = 'INITIAL',
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<MRRFirebaseContextType | null>(null);

// ----------------------------------------------------------------------
const connectToEmulatorInDev = CheckLocalDev() && process.env.REACT_APP_ENABLE_EMULATOR === 'true';

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);

export const DB = getFirestore(firebaseApp);

export const CLOUD_FUNCTIONS = getFunctions(firebaseApp)

if (connectToEmulatorInDev) {
  // These emulators let us use Google Cloud Functions in local dev (to avoid one loop blowing our budget).
  const urlBase = 'localhost'
  const authPort = 3003
  const firestorePort = 3002
  const functionsPort = 3000

  connectAuthEmulator(AUTH, `http://${urlBase}:${authPort}`)

  connectFirestoreEmulator(DB, urlBase, firestorePort)

  connectFunctionsEmulator(CLOUD_FUNCTIONS, urlBase, functionsPort)
}

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [emailVerified, setEmailVerified] = useState(false);
  const [fbToken, setFBToken] = useState('');
  const [adminSessionChanging, setAdminSessionChanging] = useState(false);

  const logout = useCallback(() => {
    //[[USER LOGOUT]]
    signOut(AUTH);
  }, []);

  const initialize = useCallback(() => {
    try {
      onAuthStateChanged(AUTH, async (firebaseUser) => {
        // spammy
        //console.log('onAuthStateChanged', firebaseUser);

        if (firebaseUser) {
          const newFBToken = await firebaseUser.getIdToken();
          setFBToken(newFBToken);

          // Here we are keeping track of whether the user's email is verified.
          setEmailVerified(firebaseUser.emailVerified);

          //! At this point AuthGuard will know whether to display the verify email page
          //! or continue with the application.

          const userRef = doc(DB, 'users', firebaseUser.uid);

          const docSnap = await getDoc(userRef);

          if (!docSnap || !docSnap.exists()) {
            //NOTE: In this case, the Firestore record has been deleted (or something else
            //      is keeping us from fetching it). The user _is_ authorized with a valid
            //      Firebase session, so We'll send them to a 'fix your profile info' page.

            console.warn('Firestore document does not exist on valid login.');

            //[[USER LOGOUT]]
            logout();
            return;
          }

          // get the user's Firestore record
          const profile = docSnap.data();

          if (!profile) {
            throw new Error('Firestore profile not found on valid login.');
          }

          //NOTE: We need to check whether brand claims are set, and if not, post them here.
          //      This is because a user could arrive via an email with a sign-in link (from our admin tools).
          //
          //UPDATE: This is no longer part of the design. We're using all phone and email OTPs for sign-in.
          //        This is fine as a failsafe.
          await firebaseUser.getIdTokenResult()
            .then(async (idTokenResult) => {
              const { claims } = idTokenResult;
              if (!claims.brand_code || !claims.brand_id) {
                const brandRequest = httpsCallable(CLOUD_FUNCTIONS, cloud_updateUserBrand);
                await brandRequest({ email: claims.email, brand: brandConfig.brandCode, postAuth: true });
              }
            })
            .catch((error) => {
              console.error('Error getting ID token result:', error);
            });

          if (!profile.isVerified && firebaseUser.emailVerified) {
            console.log('update out-of-sync Firestore record');
            const setUserAsEmailVerified = httpsCallable(CLOUD_FUNCTIONS, 'setUserAsEmailVerified');
            setUserAsEmailVerified()
            profile.isVerified = true;
          }

          const newAccountUser = new UserAccountGeneral(
            profile.id,
            profile.avatarUrl,
            profile.name,
            profile.email,
            profile.phoneNumber,
            profile.address,
            profile.country,
            profile.state,
            profile.city,
            profile.zipCode,
            profile.company,
            profile.isVerified,
            profile.status,
            profile.role,
            profile.photoURL,
            profile.externalAccountID,
            profile.nameFirst,
            profile.nameLast,
            profile.salutation,
            '', // created date
            '', // last refresh date
            '' // last login date
          );

          newAccountUser.SetFirebaseUser(firebaseUser);

          const newAccountJSON = newAccountUser.ExportAsFirestoreJSON();

          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              user: {
                ...newAccountJSON
              },
            },
          });
          setAdminSessionChanging(false);
        } else {
          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      // statusCheckPending = false;
      console.error(error);
    }
  }, [logout]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    await signInWithEmailAndPassword(AUTH, email, password);
  }, []);


  // REGISTER
  const register = useCallback(
    async (email: string, password: string) => {
      const cloudFunction = httpsCallable<any, any>(CLOUD_FUNCTIONS, cloud_userSignUp);

      const data = {
        email,
        password
      }
      try {
        await cloudFunction(data)
        await login(email, password);
        return true
      } catch (error) {
        console.error('sign up error', error);

        //NOTE: We had issues due to problems w/ the React Firebase error handling libs. Solved them, and the
        //      TLDR is "look for error.message".
        //
        //SEE: https://medium.com/geekculture/how-to-pass-errors-nicely-to-front-end-with-firebase-cloud-functions-6f224072eae4
        //MORE: https://stackoverflow.com/questions/63131012/how-to-throw-a-custom-error-on-a-firebase-callable-cloud-function

        // rethrow to the form's error handling & display
        throw error;
      }
    },
    [login]
  );

  const createUser = useCallback(
    async (
      email: string,
      role: string,
      requireVerify: boolean,
      firstName: string = '',
      lastName: string = '',
      fullModel: Record<string, string | number | boolean> | null = null) => {

      const cloudFunction = httpsCallable(CLOUD_FUNCTIONS, cloud_createUserAsAdmin);

      const data = {
        email,
        role,
        requireVerify,
        firstName,
        lastName,
        globalAccess: fullModel?.globalAccess || false,
      }

      if (fullModel) {
        // this field is reinterpreted for create
        fullModel.requireVerify = fullModel.isVerified;

        // ensure the redundant fields match in full-model create mode
        if (fullModel.email !== email
          || fullModel.role !== role
          || fullModel.requireVerify !== requireVerify
          || fullModel.nameFirst !== firstName
          || fullModel.nameLast !== lastName) {
          throw new Error('Inconsistent user values.'); // this is public
        }
        // send up countries by code, not label (NOTE: We need labels that work with Minimal's dropdowns)
        if (typeof fullModel.country === 'string') {
          const modelCountry = fullModel.country.toLowerCase();
          const searchedCountry = countryConfig.find((country) => country.code.toLowerCase() === modelCountry || country.name.toLowerCase() === modelCountry)
          if (searchedCountry) {
            // ...replace it with the label's code
            fullModel.country = searchedCountry.code;
          } else {
            fullModel.country = '';
          }
        } else {
          fullModel.country = '';
        }

        let foundCountryConfig: CountryConfig | undefined;
        if (fullModel.country) {
          foundCountryConfig = countryConfig.find((c) => { return c.code === fullModel.country; });
        }
        const sfPhone = (fullModel.phoneNumber && foundCountryConfig) ? writePhoneForSFAccount(foundCountryConfig, fullModel.phoneNumber as string) : fullModel.phoneNumber;
        fullModel.phoneNumber = sfPhone;
      }

      const cloudResult = cloudFunction(fullModel !== null ? fullModel : data)
        .then(async (result) => {
          if (verboseLogging) { console.log('admin create user result', result); }
          // console.log('user created by admin', result)
          return result;
        })
        .catch((error) => {
          console.error('admin create user error', error);

          //NOTE: In this portal there is no self-service user create. That can only be done by admins.
          //      If that changes, we may not want to report all of these errors to Sentry.
          sendToSentry(error, null, sentryAdminActionsFingerprint, cloud_createUserAsAdmin);

          throw error;
        });

      return cloudResult;
    },
    []
  );


  const updateUser = useCallback((updatedUserModel: UserAccountGeneral) => {
    const updatedUserJSON = updatedUserModel.ExportAsFirestoreJSON();
    console.log('update session user: ' + updatedUserModel);

    dispatch({
      type: Types.INITIAL,
      payload: {
        isAuthenticated: true,
        user: { ...updatedUserJSON }
      },
    });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      method: 'firebase',
      login,
      logout,
      register,
      // MRR additions
      emailVerified,
      createUser,
      updateUser,
      fbToken,
      setFBToken,
      adminSessionChanging,
      setAdminSessionChanging
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      login,
      logout,
      register,
      // MRR additions
      emailVerified,
      createUser,
      updateUser,
      fbToken,
      setFBToken,
      adminSessionChanging,
      setAdminSessionChanging
    ]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}

//---------------------------------
const TOO_MANY_ATTEMPTS_MESSAGE = 'An email has already been sent.' //[I18N]
const OPERATION_NOT_ALLOWED_MESSAGE = 'There was a problem. Please contact the Owner Experience team.' //[I18N]
const PROBLEM_SENDING_EMAIL_VERIFICATION_MESSAGE = 'There was a problem sending your verification email. Please try again.' //[I18N]
const NOT_PERFORMABLE_OPERATION_MESSAGE = 'This operation is not performable in this context.'
function handleSendEmailVerificationError(errorCode: FirebaseError['code'], setError?: (msg: string) => void) {
  console.log()
  switch (errorCode) {
    case AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER:
      if (typeof setError === 'function') {
        setError(TOO_MANY_ATTEMPTS_MESSAGE)
      }
      throw new Error(TOO_MANY_ATTEMPTS_MESSAGE)
    case AuthErrorCodes.OPERATION_NOT_ALLOWED:
      if (typeof setError === 'function') {
        setError(OPERATION_NOT_ALLOWED_MESSAGE)
      }
      throw new Error(OPERATION_NOT_ALLOWED_MESSAGE)
    default:
      if (typeof setError === 'function') {
        setError(PROBLEM_SENDING_EMAIL_VERIFICATION_MESSAGE)
      }
      throw new Error(PROBLEM_SENDING_EMAIL_VERIFICATION_MESSAGE)
  }
}

export async function beginFirebaseEmailVerification(firebaseUser: User,
  setError?: (msg: string) => void,
  targetUser: null | User = null) {
  if (targetUser === null) {
    if (!firebaseUser || firebaseUser.emailVerified) {
      throw new Error(NOT_PERFORMABLE_OPERATION_MESSAGE)
    }
  }
  else if (targetUser?.emailVerified) {
    throw new Error(NOT_PERFORMABLE_OPERATION_MESSAGE)
  }
  const url = new URL(window.location.href)
  url.pathname = PATH_HOME.root
  const actionCodeSettings: ActionCodeSettings = {
    url: url.href,
    handleCodeInApp: true
  };
  sendEmailVerification(targetUser ? targetUser : firebaseUser, actionCodeSettings)
    .then(() => {
      console.log('Firebase email-verification sent for unverified account')
    })
    .catch((error: FirebaseError) => {
      console.log('send email error', error);
      return handleSendEmailVerificationError(error.code, setError);
    });
}

export function removeSessionAdmin() {
  window.sessionStorage.removeItem(ADMIN_STORED_IN_SESSION_KEY);
  window.sessionStorage.removeItem(ADMIN_STORED_IN_SESSION_ROLE);
  window.sessionStorage.removeItem(RESTORE_GUID_STORED_IN_SESSION_KEY);

  // wipe the RTK Query cache
  StoreDispatch(apiSlice.util.resetApiState());
}