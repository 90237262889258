import { Button, Typography, Grid, Divider, } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { FDateFormat, fDate } from '../../../utils/formatTime';
import Iconify from '../../../components/iconify/Iconify';
import { ReservationGeneral, ReservationGuestType } from '../../../models/mrr/reservationGeneral';
import { DetailRow, DetailColumn, DetailContainer } from '../../../layouts/simple/DetailColumnAndRow';
import { fCurrency } from '../../../utils/formatNumber';

// ----------------------------------------------------------------------
export type confirmActionT = 'edit' | 'cancel' | 'drop' | 'edit_confirm'
type Props = {
  reservation: ReservationGeneral | null;
  handleOpenCancelConfirmed: VoidFunction;
  handleOpenDropConfirmed: VoidFunction;
  disableActions?: boolean
  // onNav: (id: string) => void;
  // confirmAction: confirmActionT | null;
  // openConfirm: boolean;
  // openEditDatePicker: boolean;
  // openPopover: HTMLElement | null;
  // selectedRow: HTMLElement | null;
  // setConfirmAction: Dispatch<SetStateAction<confirmActionT | null>>
  // setOpenConfirm: Dispatch<SetStateAction<boolean>>
  setOpenEditDatePicker: Dispatch<SetStateAction<boolean>>,
  // setOpenPopover: Dispatch<SetStateAction<HTMLElement | null>>
  // setSelectedRow: Dispatch<SetStateAction<HTMLElement | null>>
  handleOpenDropinForm: VoidFunction
};
// ----------------------------------------------------------------------

export default function ReservationDetails({
  reservation,
  handleOpenCancelConfirmed,
  handleOpenDropConfirmed,
  setOpenEditDatePicker,
  disableActions,
  handleOpenDropinForm,
}: Props) {
  if (!reservation) {
    return null
  }
  return (
    <DetailContainer>
      <Grid item>
        <Typography variant='h5'>
          {reservation.name_detailed}
        </Typography>
        <Typography variant='subtitle1' sx={{ color: 'text.disabled' }}>
          {reservation.name}
        </Typography>
      </Grid>

      <DetailRow>
        {reservation.guest_name && <DetailColumn>
          <Typography variant="overline" sx={{ color: 'text.disabled' }}>Guest</Typography>
          <Typography variant="body1">{reservation.guest_name}</Typography>
        </DetailColumn>}
        <DetailColumn fullWidth={reservation.guest_name ? false : true}>
          <Typography variant="overline" sx={{ color: 'text.disabled' }}>Type</Typography>
          <Typography variant="body1">{reservation.type}</Typography>
        </DetailColumn>
      </DetailRow>

      <DetailRow>
        <DetailColumn>
          <Typography variant="overline" sx={{ color: 'text.disabled' }}>Check In</Typography>
          <Typography variant="body1">{fDate(reservation.check_in_ISO, FDateFormat.localized_us_numeric_month_day_year)}</Typography>
        </DetailColumn>

        <DetailColumn>
          <Typography variant="overline" sx={{ color: 'text.disabled' }}>Check Out</Typography>
          <Typography variant="body1">{fDate(reservation.check_out_ISO, FDateFormat.localized_us_numeric_month_day_year)}</Typography>
        </DetailColumn>
      </DetailRow>


      <DetailRow>
        <DetailColumn third={(reservation.guest_type === ReservationGuestType.Guest)}>
          <Typography variant="overline" sx={{ color: 'text.disabled' }}>Nights</Typography>
          <Typography variant="body1">{reservation.nights}</Typography>
        </DetailColumn>
        {(reservation.guest_type === ReservationGuestType.Guest) &&
          <>
            <DetailColumn third>
              <Typography variant="overline" sx={{ color: 'text.disabled' }}>Amount</Typography>
              <Typography variant="body1">{!reservation.rental_amount ? '-' : fCurrency(reservation.rental_amount)}</Typography>
            </DetailColumn>
            <DetailColumn third>
              <Typography variant="overline" sx={{ color: 'text.disabled' }}>Rate</Typography>
              <Typography variant="body1">{!reservation.nightly_rate ? '-' : fCurrency(reservation.nightly_rate)}</Typography>
            </DetailColumn>
          </>
        }
        {(reservation.guest_type !== ReservationGuestType.Guest) &&
          <DetailColumn>
            <Typography variant="overline" sx={{ color: 'text.disabled' }}>Booked On</Typography>
            <Typography variant="body1">{fDate(reservation.booking_date_ISO, FDateFormat.localized_us_numeric_month_day_year)}</Typography>
          </DetailColumn>
        }
      </DetailRow>
      {(reservation.guest_type === ReservationGuestType.Guest) &&
        <DetailRow>
          <DetailColumn fullWidth>
            <Typography variant="overline" sx={{ color: 'text.disabled' }}>Booked On</Typography>
            <Typography variant="body1">{fDate(reservation.booking_date_ISO, FDateFormat.localized_us_numeric_month_day_year)}</Typography>
          </DetailColumn>
        </DetailRow>
      }
      {(typeof reservation.owner_pays_guest_clean === 'boolean'
        && (reservation.guest_type === ReservationGuestType.Guest
          || reservation.guest_type === ReservationGuestType.OwnerGuest)) && <DetailRow>
          <DetailColumn fullWidth>
            <Typography variant="overline" sx={{ color: 'text.disabled' }}>Guest paying final cleaning</Typography>
            <Typography variant="body1" textTransform='capitalize'>{reservation.owner_pays_guest_clean ? "No" : "Yes"}</Typography>
          </DetailColumn>
        </DetailRow>
      }
      {!disableActions && (
        <>
          <Divider sx={{ my: 2 }} />
          {reservation.guest_type === ReservationGuestType.Owner
            || reservation.guest_type === ReservationGuestType.OwnerGuest
            ? <DetailRow>
              <DetailRow>
                <DetailColumn fullWidth>
                  <Button onClick={() => setOpenEditDatePicker(true)} startIcon={<Iconify icon="eva:edit-2-outline" />} fullWidth variant='contained'>Change Dates</Button>
                </DetailColumn>
              </DetailRow>
              <DetailRow>
                <DetailColumn fullWidth>
                  <Button onClick={handleOpenCancelConfirmed} startIcon={<Iconify icon="eva:trash-2-outline" />} fullWidth variant='contained' color='error'>Cancel Booking</Button>
                </DetailColumn>
              </DetailRow>
            </DetailRow>
            :
            <DetailRow>
              <DetailColumn fullWidth>
                <Button onClick={handleOpenDropinForm} startIcon={<Iconify icon="eva:car-outline" />} fullWidth variant='contained'>Request Drop-in</Button>
              </DetailColumn>
            </DetailRow>
          }
        </>
      )}
    </DetailContainer>
  );
}
