import { EventInput } from "@fullcalendar/core";
import { ReservationGeneral, ReservationGuestType } from "./reservationGeneral";
import { TaskGeneral } from "./taskGeneral";
import { fCurrency } from "../../utils/formatNumber";

export enum CalendarReservationContentMode {
    All = 'all',
    SingleListing = 'single-listing'
}

class CalendarEvent implements EventInput {
    constructor(
        public readonly id: string,
        protected _start: Date | undefined,
        protected _end: Date | undefined,
        protected _allDay: boolean = true,
    ) { }

    get start() {
        return this._start
    }
    get end() {
        return this._end
    }
    get allDay() {
        return this._allDay
    }
}

export class CalendarReservation extends CalendarEvent {
    public readonly title: string

    constructor(
        start: Date | undefined,
        end: Date | undefined,
        public readonly model: ReservationGeneral,
        public readonly color: string,
        public readonly textColor: string,
        public readonly contentMode: CalendarReservationContentMode,
    ) {
        super(model.id, start, end, false)
        this.title = CalendarReservation.createTitle(contentMode, model);
        if (contentMode === CalendarReservationContentMode.SingleListing) {
            this.color = '#00AB55';
            this.textColor = '#00AB55';
        }
    }
    static createTitle(mode: CalendarReservationContentMode, reservation_model: ReservationGeneral) {
        let title = '';

        if (mode === CalendarReservationContentMode.SingleListing) {
          title = reservation_model.guest_name;
          if (reservation_model.name) {
            title += ` - ${reservation_model.name}`;
          }
        } else {
          title = reservation_model.unit_id;
          if (reservation_model.guest_name) {
            title += ` - ${reservation_model.guest_name}`;
          }
        }
        if (reservation_model.type === ReservationGuestType.Guest) {
          // only add rental rate if reservation is type Guest
          title += ` - ${fCurrency(reservation_model.rental_amount)}`;
        }
        return title;
      }
};

export class CalendarTasks extends CalendarEvent {
    constructor(
        public readonly title: string,
        start: Date | undefined,
        end: Date | undefined,
        public readonly model: TaskGeneral,
        public readonly color: string,
        public readonly textColor: string,
        public readonly contentMode: CalendarReservationContentMode,
    ) {
        super(model.id, end, start)

        if (!model.completed_time && !model.start_time && !model.dueDate) {
            console.warn('Undisplayable task event model', model)
        }

        if (model.start_time && model.completed_time) {
            this._start = model.start_time
            this._end = model.completed_time
            this._allDay = false
        } else if (model.completed_time) {
            this._start = model.completed_time
            this._end = model.completed_time
        } else if (model.dueDate) {
            this._start = model.dueDate
            this._end = model.dueDate
        }
    }
}